import React  from "react";
import { Button } from "react-bootstrap";
import { Link } from "gatsby";
import imgAS from "../assets/image/home-6/png/app-store-img.png";
import imgPS from "../assets/image/home-6/png/play-store-img.png";
import PageWrapper from "../components/PageWrapper";
import { useTranslation } from "react-i18next";

export default function Download() {
  const { t, i18n } = useTranslation();
   
    return (
        <>
   <PageWrapper themeConfig={{
          headerClassName: "pt-13 pt-lg-11",
          headerLogoClassName: "mx-auto text-center",
        }}>
            <div className="min-height-100vh d-flex align-items-center pt-15 pb-13 pt-lg-32 pb-lg-27 bg-default-3">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 col-md-8 col-xs-10">
                <div className="pt-9 pt-sm-13 pb-10 px-6 px-sm-1 px-md-7 px-lg-15 px-xl-16"> <h2 className="text-center mb-4">{t("download_now")} </h2></div>
      

       <div className="btn-group d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                  <Link to="https://apps.apple.com/us/app/the-digi/id1560881860">
                    <img
                      className="w-sm-100 w-xl-auto max-h-64 mb-3 mb-lg-0 mx-auto mx-sm-3"
                      src={imgAS}
                      alt=""
                    />
                  </Link>
                  <Link to="https://play.google.com/store/apps/details?id=com.digitalglobal.marketplace">
                    <img
                      className="w-sm-100 w-xl-auto max-h-64 mb-3 mb-lg-0 mx-auto mx-sm-3"
                      src={imgPS}
                      alt=""
                    />
                  </Link>
                </div>
       </div></div></div></div>
    </PageWrapper> 
    </>
    )
};